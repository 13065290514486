<!-- Preloader -->
<div class="preloader">
    <div class="loader">
        <div class="loader-outter"></div>
        <div class="loader-inner"></div>
    </div>
</div>
<!-- End Preloader -->

<app-header *ngIf="!(location == '/coming-soon')"></app-header>
<router-outlet></router-outlet>
<app-footer *ngIf="!(location == '/coming-soon')"></app-footer>