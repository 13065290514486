import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AirtimeService } from 'src/app/services/prymo/airtime.service';
import { InternetDataService } from 'src/app/services/prymo/internet-data.service';
import { GlobalAirtimeService } from 'src/app/services/reloadly/global-airtime.service';

@Component({
  selector: 'app-receipt',
  standalone: true,
  imports: [],
  templateUrl: './receipt.component.html',
  styleUrl: './receipt.component.scss'
})
export class ReceiptComponent implements OnInit {
  public paramsObject: any = {};
  public topupValues: any = {};

  public airOkResponse: any = {
    status: '',
    message: '',
    reason: '',
    trxn: '',
    status_code: '',
    local_trxn_code: '',
    balance_before: '',
    balance_after: '',
    network: '',
    price: ''
  }

  public payResponse: any = {
    "status": "Access Denied",
    "code": "040",
    "reason": "You are not allowed to transact with MTN (Mtn)",
    "r_switch": "MTN",
    "subscriber_number": "0244588584",
    "amount": "1",
    "channel": "mobile",
    "currency": "GHS",
    "transaction_id": "861164120621"
  }

  isLoading = true;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private airtimeService: AirtimeService,
    private globalAirService: GlobalAirtimeService,
    private internetService: InternetDataService,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    //query params from Payswitch
    this.route.queryParamMap
      .subscribe((params) => {
        this.paramsObject = { ...params.keys, ...params };
        console.log('paramsObject ==>', this.paramsObject);
        localStorage.setItem('payRes', JSON.stringify(this.paramsObject.params));
      });
    let payValues = JSON.parse(localStorage.getItem('payRes'));

    //get saved topup form data
    const tval = JSON.parse(localStorage.getItem('tparams'));
    const tval2 = JSON.parse(localStorage.getItem('topup1'));
    console.log("tval from localstorage >>> ", tval);
    console.log("tval2 val from localstorage >>> ", tval2);

    if (payValues.status == 'Approved' || payValues.code === '000') {
      if (tval != null || tval != '') {
        if (tval.transType == 'AIRTIMETOPUP') {
          this.creditCustomerAirtime(tval);
        } else if(tval.transType == 'INTERNETDATA') {
          this.buyData(tval);
        } else {
          this.makeAsyncAirtimeTopup(tval);
        }
        // this.router.navigate(['flash']) ;

      } else if (tval2 != null || tval2 != '') {
        this.creditCustomerAirtime(tval2);
        // this.router.navigate(['flash']) ;
      }

    } else if (payValues.status == 'cancelled' || payValues.code == '900') {

      // if (tval != null || tval != '') {
      //   if(tval.transType == 'AIRTIMETOPUP'){
      //     this.creditCustomerAirtime(tval);
      //   }else {
      //     this.buyData(tval);
      //   }
      //   // this.router.navigate(['flash']) ;

      // } else if (tval2 != null || tval2 != '') {
      //   this.creditCustomerAirtime(tval2);
      // }

      this.airOkResponse.status = payValues.code;
      this.airOkResponse.message = payValues.status || payValues.reason;
      this.airOkResponse.trxn = payValues.transaction_id;
      this.router.navigate(['receipt']);
    } else {
      this.airOkResponse.status = payValues.code;
      this.airOkResponse.reason = payValues.reason;
      this.airOkResponse.trxn = payValues.transaction_id;
      this.router.navigate(['receipt']);
    }

  }

  creditCustomerAirtime(formData: any) {
    console.log('receiptComponent:airtime  tval >>>>', formData);
    this.airtimeService.buyAirtimeTopup(formData)
      .subscribe({
        next: (c2Res) => {
          console.log(`airtime credit response ==> ${JSON.stringify(c2Res)}`);
          this.airOkResponse.status = c2Res.status || c2Res.code;
          this.airOkResponse.message = c2Res.message || c2Res.reason;
          this.airOkResponse.trxn = c2Res.trxn;
          this.airOkResponse.local_trxn_code = c2Res['local_trxn-code'];
          this.airOkResponse.network = c2Res.network;
          this.airOkResponse.balance_before = c2Res.balance_before;
          this.airOkResponse.balance_after = c2Res.balance_after;

          this.airOkResponse.price = '';
          this.isLoading = false;
        },
        error: (c2Error) => {
          console.log('Credit Airtime',c2Error);
          this.isLoading = false;
          // alert('Topup error: ' + JSON.stringify(c2Error.error));
          this.toastr.error('Credit Airtime Error: ' + c2Error, 'Credit');

        }
      });
  }

 public buyData(iData: any) {
    console.log('receiptComponent:internet  tval >>>>', iData);
    this.internetService.buyInternetData(iData)
      .subscribe({
        next: (bdRes) => {
          console.log(`RECEIPT BUY INTERNET DATA ==> ${JSON.stringify(bdRes)
          }`);
        this.airOkResponse.status = bdRes.status;
        this.airOkResponse.message = bdRes.message;
        this.airOkResponse.trxn = bdRes.trxn;
        this.airOkResponse.local_trxn_code = bdRes['local_trxn-code'];
        this.airOkResponse.network = bdRes.network;
        this.airOkResponse.balance_before = bdRes.balance_before;
        this.airOkResponse.balance_after = bdRes.balance_after;

        this.airOkResponse.price = '';
        this.isLoading = false;

        this.router.navigate(['receipt']);
         },
        error: (bdError) => { 
          console.log(bdError);
          this.isLoading = false;
          // alert('DATA ERROR =>>' + JSON.stringify(bdError.error));
          this.toastr.error('Internet Data Error: ' +bdError, 'Internet Data');

        }
      });
  }

  public makeAsyncAirtimeTopup(matData: any) {
    console.debug('CHECKOUT-PAGE Make ASYNC AIRTIME TOPUP DATA ==>' + matData);
    // this.showLoader();
    this.globalAirService.makeAsyncAirtimeTopup(matData)
      .subscribe({
        next: (matRes) => {
          console.log('GNOPBC RES ===> ' + JSON.stringify(matRes));

          if (matRes.status == 'SUCCESSFUL') {
            const navigationExtras: NavigationExtras = {
              queryParams: {
                special: JSON.stringify(matRes)
              }
            };
            // this.hideLoader();
            this.router.navigate(['./receipt'], navigationExtras);
          }
        },
        error: (matError) => {
          console.log(`GNOPBC ERROR ===>${JSON.stringify(matError)}`);
          const navigationExtras: NavigationExtras = {
            queryParams: {
              special: JSON.stringify(matError)
            }
          };
          // this.hideLoader();
          // this.router.navigate(['./receipt'], navigationExtras);
        }
      });
  }
  OnPrint() {
    window.print();
    window.localStorage.removeItem('tparams');
    window.localStorage.removeItem('topup1');
  }

  goToDashboard(): void {
    window.localStorage.removeItem('tparams');
    window.localStorage.removeItem('topup1');
    // this.router.navigate(['/']);
    window.location.href = 'https://advansistechnologies.com';
  }

}
