import { Component, OnInit } from '@angular/core';
import { CommonModule, Location } from '@angular/common';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PreviousRouteService } from 'src/app/services/previouse.route.service';
import { AirtimeService } from 'src/app/services/prymo/airtime.service';
import { ReloadlyService } from 'src/app/services/reloadly/reloadly.service';
import { ToastrService } from 'ngx-toastr';
import { PayswitchService } from 'src/app/services/payment/payswitch.service';
declare const $: any;


@Component({
  selector: 'app-airtime-topup',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule],
  templateUrl: './airtime-topup.component.html',
  styleUrl: './airtime-topup.component.scss'
})
export class AirtimeTopupComponent implements OnInit {
  public countries: any = [];
  public operators: any = [];
  public globalAirForm!: FormGroup;
  public airtimeForm!: FormGroup;

  isLoading = true;

  globalAirParams: any = {
    operatorId: 0,
    amount: 0.0,
    customIdentifier: "",
    recipientEmail: "advansis.technology@gmail.com",
    recipientNumber: "",
    recipientCountryCode: "",
    senderNumber: "0244588584",
    senderCountryCode: "GH",
    description: "",
    redirectURL: "https://advansistechnologies.com/receipt",
    customerEmail: "tech@advansistechnologies.com",
    phone: '',
    countryIsoCode: '',
    transType: "GLOBALAIRTOPUP"
  };

  topupParams: any = {
    "recipientNumber": "",
    "description": "",
    "amount": "",
    redirectURL: "https://advansistechnologies.com/receipt",
    "customerEmail": "tech@advansistechnologies.com",
    transType: 'AIRTIMETOPUP'
  };

  autOperatorsParams: any = {
    phone: '',
    countryIsoCode: ''
  };

  public checkoutURL = '';
  public pswitchObject: any = {};
  loading: any;
  loaderToShow: any;

  constructor(
    private formBuilder: FormBuilder,
    private reloadlyService: ReloadlyService,
    private airtimeService: AirtimeService,
    private previousRouteService: PreviousRouteService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private toastr: ToastrService,
    private paymentService: PayswitchService
  ) { }

  ngOnInit() {
    // global airtime-topup page formBuild
    this.globalAirForm = this.formBuilder.group({
      operatorId: [null, Validators.required],
      amount: ['', Validators.required],
      recipientEmail: [null],
      recipientNumber: ['', [Validators.required, Validators.minLength(10)]],
      recipientCountryCode: [null],
      senderNumber: ['', [Validators.required, Validators.minLength(10)]],
      senderCountryCode: [null],
      countryIsoCode: [null]
    });

    // local airtime-topup page formBuild
    this.airtimeForm = this.formBuilder.group({
      retailer: [null, Validators.required],
      recipientNumber: ['', [Validators.required, Validators.minLength(10)]],
      amount: ['', Validators.required],
      network: [null],
      description: [null],
      email: [null]
    });

    this.listCountries();
    // console.debug('OnInit COUNTRIES <== started ...');
  }

  public reloadPage() {
    setTimeout(() => {
      window.location.reload();
    }, 100);
  }

  //local form onSubmit
  // form onSubmit
  topupFormSubmit(form: any): any {
    console.log('topup formData:  >>>>', form);

    this.topupParams.recipientNumber = form.recipientNumber;
    this.topupParams.description = ' Airtime Topup to: ' + form.recipientNumber;

    // calculate amountPaid with  12 digits
    if (form.amount < 10) {
      const inputAmount: any = form.amount * 100;
      this.topupParams.amount = "000000000" + inputAmount;
      localStorage.setItem('tparams', JSON.stringify(this.topupParams));
    } else if (form.amount >= 10) {
      const inputAmount: any = form.amount * 100;
      this.topupParams.amount = "00000000" + inputAmount;
      localStorage.setItem('tparams', JSON.stringify(this.topupParams));
    }

    console.log('topform params =>>', this.topupParams);

    $('#recharge').hide('fade'); // hide submit button

    console.log('post to payment ...')


    console.log('airtimeform valid');
    this.makePayment(this.topupParams);
    $('#loading').show('slow'); // show Loading button
    $("#localModal").modal("hide");

    // this.creditCustomerAirtime(this.topupParams);
  }

  // global form onSubmit
  async globalAirFormSubmit(form: any) {
    console.log('RELOADLY TOP-UP formData:  >>>>', form);

    this.globalAirParams.recipientNumber = form.recipientNumber;
    this.globalAirParams.phone = form.recipientNumber;
    this.globalAirParams.recipientCountryCode = form.recipientCountryCode.isoName;
    this.globalAirParams.description = 'Airtime recharge for ' + form.recipientNumber;
    // this.globalAirParams.network = form.recipientCountryCode.isoName;

    // calculate amountPaid with  12 digits
    if (form.amount < 10) {
      const inputAmount: any = form.amount * 100;
      this.globalAirParams.amount = '000000000' + inputAmount;
      // await this.storage.setStorage('tparams', JSON.stringify(this.globalAirParams));
      localStorage.setItem('tparams', JSON.stringify(this.globalAirParams));

    } else if (form.amount >= 10) {
      const inputAmount: any = form.amount * 100;
      this.globalAirParams.amount = '00000000' + inputAmount;
      // await this.storage.setStorage('tparams', JSON.stringify(this.globalAirParams));
      localStorage.setItem('tparams', JSON.stringify(this.globalAirParams));

    }

    this.autOperatorsParams.phone = this.globalAirParams.recipientNumber;
    this.autOperatorsParams.countryIsoCode = this.globalAirParams.recipientCountryCode;
    await this.autoDetectOperation(this.autOperatorsParams);
    $("#interModal").modal("hide");

    // console.log('Global airstorage =>>', globalAirStore);
    // const airStore = await this.storage.getStorage('gparams');
    // console.log('Reloadly Airtime Storage', airStore);

  }

  // load countries
  listCountries() {
    this.reloadlyService.getReloadlyCountries()
      .subscribe({
        next: (lcRes) => {
          // console.log('get countries' + lcRes);
          this.countries = lcRes;
          console.debug('RELOADLY COUNTRY LIST ::>> ' + JSON.stringify(this.countries));
        },
        error: (lcError) => {
          console.error('countries error ==>' + lcError);
        }
      });
  }

  // load network operators
  listNetworkOperators() {
    this.reloadlyService.getReloadlyOperators()
      .subscribe({
        next: (lnoRes) => {
          console.log('NETWORK OPERATORS =>' + lnoRes);
          this.operators = lnoRes;
          console.debug('NETWORK OPERATORS ::: ' + JSON.stringify(this.operators));
        },
        error: (lnoError) => {
          console.error('NETWORK OPERATORS ERROR ==>' + lnoError);
        }
      });
  }

  // auto detect network operators
  async autoDetectOperation(adoData: any) {
    this.reloadlyService.autoDetectOperator(adoData)
      .subscribe({
        next: async (odoRes) => {
          console.log('AUTO NETWORK OPERATOR >==>' + odoRes);
          this.globalAirParams.countryIsoCode = odoRes.id;
          this.globalAirParams.operatorId = odoRes['operatorId'];

          // store Global Params
          const globalAirStore = localStorage.setItem('gparams', JSON.stringify(this.globalAirParams));

          console.log(`globalAirParams ===> ${globalAirStore}`);
          console.log('International topform params =>>', this.globalAirParams);

          if (this.globalAirParams.countryIsoCode) {
            // send global air params data to checkout page

            // this.router.navigate(['/checkout']);
            this.makePayment(this.globalAirParams);

          } else {
            const odoWarnMsg = 'Could not auto detect operator for given phone number';
            this.toastr.error('Auto Detection Operation: ' + odoWarnMsg, 'AutoDetect');

          }
        },
        error: (odoError) => {
          console.error('AUTO NETWORK OPERATOR ERROR >==>' + odoError);
          this.toastr.error('Network Error: ' + odoError, 'Network AutoDetect');
        }
      });
  }

  // get network operator by countryIsoCode
  getNetworkOperatorByCode(gnopbcData: any) {
    this.reloadlyService.getOperatorByCode(gnopbcData)
      .subscribe({
        next: (gnopbcRes) => {
          console.log('GNOPBC RES ===> ' + gnopbcRes);
        },
        error: (gnopbcError) => {
          console.log(`GNOPBC ERROR ===>${gnopbcError}`);
        }
      });
  }

 public makePayment(mData: any) {
    console.log(`payment params ==>${JSON.stringify(mData)}`);
    this.paymentService.makePayment(mData)
      .subscribe({
        next: (mpRes) => {
          console.log(`payment response ==> ${JSON.stringify(mpRes)}`);
          this.checkoutURL = mpRes.checkout_url;
          console.log(`checkoutUrl ==> ${JSON.stringify(this.checkoutURL)}`);
          if (mpRes.status == 'success' || mpRes.code == 200) {
            // this.router.navigate(['/flash']);
            window.location.href = `${this.checkoutURL}`;
          } else if (mpRes.code == '999') {
            // this.router.navigate(['/flash']);
            window.location.href = `${this.checkoutURL}`;
          }
          this.isLoading = false;
        },
        error: (mpError) => {
          console.log(mpError);
          this.isLoading = false;
          this.toastr.error('Payment Processing: ' + mpError, 'Payment Error');
          this.router.navigate(['/']);
        }
      });
  }

}
