import { Component } from '@angular/core';

@Component({
  selector: 'app-apps',
  standalone: true,
  imports: [],
  templateUrl: './apps.component.html',
  styleUrl: './apps.component.scss'
})
export class AppsComponent {

}
