<!-- Start Page Title Area -->
<!-- <div class="page-title-area item-bg3">
    <div class="container">
        <div class="page-title-content">
            <h2>Receipt</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Receipt</li>
            </ul>
        </div>
    </div>

    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</div> -->
<!-- End Page Title Area -->


<!-- Start Pricing Area -->
<section class="pricing-area ptb-110 bg-fafafa">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <!-- <div class="single-pricing-box">
                    <div class="pricing-header">
                        <h3>Failed</h3>
                        <p>Get your business up <br>and running</p>
                    </div>
                    <div class="price">
                        $0 <span>/m</span>
                    </div>
                    <div class="buy-btn">
                        <a routerLink="/" class="btn btn-primary">Get Started Free</a>
                    </div>
                    <ul class="pricing-features">
                        <li><i class='flaticon-tick'></i> Drag & Drop Page Builder</li>
                        <li><i class='flaticon-tick'></i> Lead Generation & Sales</li>
                        <li><i class='flaticon-tick'></i> Bot & Digital Assistants</li>
                        <li><i class='flaticon-tick'></i> Customer Service</li>
                        <li><i class='flaticon-tick'></i> Up to 1000 Subscribers</li>
                        <li><i class='flaticon-tick'></i> Unlimited Broadcasts</li>
                        <li><i class='flaticon-tick'></i> Landing Pages & Web Widgets</li>
                    </ul>
                </div> -->
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-pricing-box active">
                    <div class="pricing-header">
                        <h3>{{airOkResponse.status}}</h3>
                        <h4>{{airOkResponse.message }}</h4>
                    </div>
                    <!-- <div class="price">
                        GH¢ {{airOkResponse.balance_before - airOkResponse.balance_after| number : '1.2-2'}} <span></span>
                    </div> -->
                    <div class="card">
                        <ul class="list-group list-group-flush">
                            <li class="list-group-item">Reason: {{airOkResponse.reason}}</li>
                            <li class="list-group-item">Network: {{airOkResponse.network}}</li>
                            <li class="list-group-item">ServiceId: {{airOkResponse.trxn }}</li>
                            <!-- <li class="list-group-item">TransId: {{ airOkResponse.local_trxn_code }}</li> -->

                        </ul>
                    </div><br>

                    <div class="buy-btn">
                        <a (click)="OnPrint()" class="btn btn-primary">Print</a>
                        <a (click)="goToDashboard()" class="btn mt-3">Goto Dashboard</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <!-- <div class="single-pricing-box">
                    <div class="pricing-header">
                        <h3>Premium</h3>
                        <p>Get your business up <br>and running</p>
                    </div>
                    <div class="price">
                        $179 <span>/m</span>
                    </div>
                    <div class="buy-btn">
                        <a routerLink="/" class="btn btn-primary">Start 6 Days Free Trial</a>
                    </div>
                    <ul class="pricing-features">
                        <li><i class='flaticon-tick'></i> Drag & Drop Page Builder</li>
                        <li><i class='flaticon-tick'></i> Lead Generation & Sales</li>
                        <li><i class='flaticon-tick'></i> Bot & Digital Assistants</li>
                        <li><i class='flaticon-tick'></i> Customer Service</li>
                        <li><i class='flaticon-tick'></i> Up to 10000 Subscribers</li>
                        <li><i class='flaticon-tick'></i> Unlimited Broadcasts</li>
                        <li><i class='flaticon-tick'></i> Landing Pages & Web Widgets</li>
                    </ul>
                </div> -->
            </div>

        </div>
    </div>

    <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.png" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape/shape4.svg" alt="image"></div>
    <div class="shape-img5"><img src="assets/img/shape/shape5.svg" alt="image"></div>
    <div class="shape-img7"><img src="assets/img/shape/shape3.png" alt="image"></div>
    <div class="dot-shape1"><img src="assets/img/shape/dot1.png" alt="image"></div>
    <div class="dot-shape2"><img src="assets/img/shape/dot3.png" alt="image"></div>
    <div class="dot-shape4"><img src="assets/img/shape/dot4.png" alt="image"></div>
    <div class="dot-shape5"><img src="assets/img/shape/dot5.png" alt="image"></div>
    <div class="dot-shape6"><img src="assets/img/shape/dot6.png" alt="image"></div>

</section>