<!-- Start Free Trial Area -->
<section class="free-trial-area">
    <div class="row m-0">
        <div class="col-lg-6 col-md-12 p-0">
            <div class="free-trial-image">
                <img src="assets/img/woman2.jpg" alt="image">
            </div>
        </div>

        <div class="col-lg-6 col-md-12 p-0">
            <div class="free-trial-content">
                <h2>Start your free trial</h2>
                <form class="newsletter-form">
                    <input type="email" class="input-newsletter" placeholder="Enter your business email here">
                    <button type="submit">Sign Up Free</button>
                </form>
                <!-- <p>Test out the Advans Pay app features for 14 days, no credit card required!</p> -->
            </div>
        </div>
    </div>
</section>
<!-- End Free Trial Area -->
