<!-- Start Page Title Area -->
<!-- <div class="page-title-area item-bg2">
    <div class="container">
        <div class="page-title-content">
            <h2>Internet Data</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Buy data</li>
            </ul>
        </div>
    </div>

    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</div> -->
<!-- End Page Title Area -->

<!-- Start Services Details Area -->
<br>
<section class="services-details-area ptb-110">
    <div class="container">
        <div class="services-details-overview">

            <div class="services-details-desc">
                <h3>Make Internet Data Payment</h3>
                <form id="dataCodeForm" [formGroup]="dataCodeForm" (ngSubmit)="getDataBundle(dataCodeForm.value)">
                    <div class="form-floating mb-3">
                        <select class="form-select" id="network" aria-label="network" formControlName="network">
                            <!-- <option selected>Select Network</option> -->
                            <option value="4">MTN Ghana</option>
                            <option value="6">Telecel Ghana</option>
                            <option value="1">AirtelTigo Ghana</option>
                            <option value="3">Glo Ghana</option>
                       
                        </select>
                        <label for="network">Network Operators</label>
                    </div>
                    <button type="submit" class="btn btn-primary btn-sm mt-2">Next</button>
                </form>
                <br>

                <form id="internetForm" [formGroup]="internetForm" (ngSubmit)="onSubmit(internetForm.value)">
                    <div class="form-floating mb-3">
                        <select class="form-select" id="dataCode" aria-label="dataCode" formControlName="dataCode">
                            <option selected>Select Data bundle</option>
                            <option *ngFor="let data of dataBundle" [ngValue]="data">
                                {{data.volume }} - GH¢{{data.price}} - {{data.validity}} day(s)
                            </option>
                        </select>
                        <label for="dataCode">Data Bundles</label>
                    </div>

                    <div class="form-floating mb-3">
                        <input 
                            type="text" 
                            class="form-control" 
                            id="recipientNumber" 
                            placeholder="e.g. 0123456789"
                            formControlName="recipientNumber"
                            >
                        <label for="recipientNumber">Recipient Number</label>
                    </div>
                    <div *ngIf="internetForm.controls['recipientNumber'].invalid && (internetForm.controls['recipientNumber'].dirty || internetForm.controls['recipientNumber'].touched)"
                        class="alert alert-danger">
                        <div *ngIf="internetForm.controls['recipientNumber'].errors.required">
                            Recipient Number is required.
                        </div>
                    </div>

                    <button type="submit" class="btn btn-primary btn-sm mt-2">Proceed to Pay</button>

                </form><br><br>

                <!-- <p>Took a galley of type and scrambled it to make a type specimen book. survived not only five
                                    centuries, but also the leap into electronic remaining. Lorem Ipsum is simply dummied text of the
                                    printing and typesetting industry.
                                </p> -->
            </div>

            <div class="services-details-image wow fadeInUp">
                <img src="assets/img/advans-phone-1.png" alt="image">
            </div>

        </div>
    </div>
</section>
<!-- End Services Details Area -->