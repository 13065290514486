import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class AirtimeService {
    private awServer: string = environment.awServer;

  constructor(
    private httpClient: HttpClient
  ) { }

  public buyAirtimeTopup(mData: any): Observable<any> {
    console.log('buyAirtime service - params ==>', mData);
    return this.httpClient
        .post<any>(`${this.awServer}/api/airtime/topup`, mData)
        .pipe(
            tap(_res => this.log(`airtime service processing`)),
            catchError(this.handleError('AirtimeService', []))
        );
}

private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
        console.error(error); // log to console instead
        alert(error);
        this.log(`${operation} Failed: ${error.message}`);

        return of(result as T);
    };
}

private log(message: string) {
    console.log(message);
}

}
