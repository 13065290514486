import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PayswitchService } from 'src/app/services/payment/payswitch.service';
import { InternetDataService } from 'src/app/services/prymo/internet-data.service';
import { CommonModule, Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
declare let $: any;

@Component({
  selector: 'app-internet',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './internet.component.html',
  styleUrl: './internet.component.scss'
})
export class InternetComponent implements OnInit {
  // public transType = "internetData";
  public dataBundle: any = [];
  public internetForm: FormGroup;
  public dataCodeForm: FormGroup;

  public buyDataParams: any = {
    recipientNumber: '',
    dataCode: '',
    network: '',
    amount: '',
    description: "",
    redirectURL: "https://advansistechnologies.com/receipt",
    customerEmail: "info@advansistechnologies.com",
    transType: 'INTERNETDATA'
  };

  public networks = [
    {
      'id': 4,
      'name': 'MTN'
    },
    {
      'id': 6,
      'name': 'Vodafone Ghana'
    },
    {
      'id': 1,
      'name': 'Airtel'
    }, {
      'id': 5,
      'name': 'Tigo'
    }, {
      id: 3,
      name: 'Glo Ghana'
    }
  ];

  private checkoutURL: string = '';
  public paySwitchParams: any = {};

  isLoading: boolean = false;
  submitted: boolean = false;
  public inet = '';

  constructor(
    private internetService: InternetDataService,
    private paymentService: PayswitchService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private formBuilder: FormBuilder,
    private toastr: ToastrService
  ) {
    // this.dataBundle = this.dataService.mtnBundles;
  }

  ngOnInit() {
    $('#internetForm').hide();
    this.dataCodeForm = this.formBuilder.group({
      network: ['', Validators.required],
    });

    this.internetForm = this.formBuilder.group({
      recipientNumber: [
        '', Validators.required
      ],
      dataCode: [
        '', Validators.required
      ],
      network: [null],
      price: [null],
      amount: [null]
    });

    // console.log(`data bundlelist =>> ${this.dataService}`)

  }

  getDataBundle(gbData: any) {
    this.internetService.internetBundleList(gbData)
      .subscribe({
        next: (gdbRes) => {
          if (gdbRes.status == 'OK') {
            $('#dataCodeForm').hide();
            this.isLoading = false;

            const nList = gdbRes.bundles;
            console.log('nList::: ', nList);

            this.dataBundle = nList;
            $('#internetForm').show('slideUp');
          }

        },
        error: (gdbError) => {
          console.log('Load Data Bundles >>>', JSON.stringify(gdbError));
          this.isLoading = false;
          // alert('DATA BUNDLE LIST ERROR =>>' + JSON.stringify(gdbError.error));
          this.toastr.error('Error'+gdbError,'Data Bundle');
        }
      });
  }

  public onSubmit(formData: any) {
    console.log(`buy data form ==> ${JSON.stringify(formData)
      }`);
    this.buyDataParams.recipientNumber = formData.recipientNumber;
    this.buyDataParams.dataCode = formData.dataCode.plan_id;
    this.buyDataParams.network = 0;
    // this.buyDataParams.amount = parseInt(formData.dataCode.price);
    this.buyDataParams.description = ' Internet Data Topup to: ' + formData.recipientNumber;

    // calculate amountPaid with  12 digits
    const convPrice = parseInt(formData.dataCode.price);
    if (convPrice < 10) {
      const inputAmount = convPrice * 100;
      this.buyDataParams.amount = "000000000" + inputAmount;
      localStorage.setItem('tparams', JSON.stringify(this.buyDataParams));
    } else if (convPrice >= 10 && convPrice < 100) {
      const inputAmount: any = convPrice * 100;
      this.buyDataParams.amount = "00000000" + inputAmount;
      localStorage.setItem('tparams', JSON.stringify(this.buyDataParams));
    } else if (convPrice >= 100) {
      const inputAmount: any = convPrice * 100;
      this.buyDataParams.amount = "0000000" + inputAmount;
      localStorage.setItem('tparams', JSON.stringify(this.buyDataParams));
    }

    console.log(`selected data params =>> ${JSON.stringify(this.buyDataParams)}`);

    console.log('call buydata app ...');
    this.makePayment(this.buyDataParams);

  }



  // buyData(iData: any) {
  //     console.log(`buy data form ==> ${
  //         JSON.stringify(iData)
  //     }`);

  //     this.internetService.buyInternetData(iData).subscribe(res => {
  //         console.log(`BUY INTERNET DATA ==> ${
  //             JSON.stringify(res)
  //         }`);
  //         // alert(` ${
  //         //     JSON.stringify(res)
  //         // }`);
  //         this.isLoading = false;

  //         this.router.navigate(['/receipt']);
  //     }, (err) => {
  //         console.log(err);
  //         this.isLoading = false;
  //         alert('DATA ERROR =>>' + JSON.stringify(err.error));
  //     });
  // }

  makePayment(mData: any) {
    this.paymentService.makePayment(mData)
      .subscribe({
        next: (mpRes) => {
          console.log(`payment response ==> ${JSON.stringify(mpRes)}`);
          this.checkoutURL = mpRes.checkout_url;
          console.log(`checkoutUrl ==> ${JSON.stringify(this.checkoutURL)}`);
          if (mpRes.status == 'success' || mpRes.code == 200) {
            window.location.href = `${this.checkoutURL}`;
          } else if (mpRes.code == '999') {
            window.location.href = `${this.checkoutURL}`;
          }
          this.isLoading = false;
          // alert('Topup successfully processed.');
          this.toastr.success('Payment', 'Payment processed.');
          // this.creditCustomerAirtime(res);
          // this.router.navigate(['/']);
        },
        error: (mpError) => {
          console.log('Payment Error', mpError);
          this.isLoading = false;
          this.toastr.error('Payment Error' + mpError, 'Payment');
          // alert(err.error);
          this.router.navigate(['/']);
        }
      });
  }

}
