<!-- Start About Area -->
<section class="about-area ptb-110">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <img src="assets/img/about/about1.jpg" alt="image">
                    <img src="assets/img/about/about2.jpg" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <h2>Engaging New Audiences through Smart Approach</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit.</p>
                    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage.</p>
                    <a routerLink="/about" class="btn btn-primary">Discover More</a>
                </div>
            </div>
        </div>
    </div>

    <div class="shape-img1"><img src="assets/img/shape/shape1.png" alt="image"></div>
    <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.png" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape/shape4.svg" alt="image"></div>
    <div class="shape-img5"><img src="assets/img/shape/shape5.svg" alt="image"></div>
    <div class="shape-img6"><img src="assets/img/shape/shape6.png" alt="image"></div>
    <div class="dot-shape1"><img src="assets/img/shape/dot1.png" alt="image"></div>
    <div class="dot-shape2"><img src="assets/img/shape/dot3.png" alt="image"></div>
</section>
<!-- End About Area -->
