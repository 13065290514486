<div class="tab pricing-tab">
    <ul class="tabs">
        <li><a href="#">
            Monthly Plan
        </a></li>
        <li><a href="#">
            Yearly Plan
        </a></li>
    </ul>

    <div class="tab-content">
        <div class="tabs-item">
            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="single-pricing-box">
                        <div class="pricing-header">
                            <h3>Free</h3>
                            <p>Get your business up <br>and running</p>
                        </div>
                        <div class="price">
                            $0 <span>/m</span>
                        </div>
                        <div class="buy-btn">
                            <a routerLink="/" class="btn btn-primary">Get Started Free</a>
                        </div>
                        <ul class="pricing-features">
                            <li><i class='flaticon-tick'></i> Drag & Drop Page Builder</li>
                            <li><i class='flaticon-tick'></i> Lead Generation & Sales</li>
                            <li><i class='flaticon-tick'></i> Bot & Digital Assistants</li>
                            <li><i class='flaticon-tick'></i> Customer Service</li>
                            <li><i class='flaticon-tick'></i> Up to 1000 Subscribers</li>
                            <li><i class='flaticon-tick'></i> Unlimited Broadcasts</li>
                            <li><i class='flaticon-tick'></i> Landing Pages & Web Widgets</li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-pricing-box active">
                        <div class="pricing-header">
                            <h3>Pro</h3>
                            <p>Get your business up <br>and running</p>
                        </div>
                        <div class="price">
                            $149 <span>/m</span>
                        </div>
                        <div class="buy-btn">
                            <a routerLink="/" class="btn btn-primary">Start 3 Days Free Trial</a>
                        </div>
                        <ul class="pricing-features">
                            <li><i class='flaticon-tick'></i> Drag & Drop Page Builder</li>
                            <li><i class='flaticon-tick'></i> Lead Generation & Sales</li>
                            <li><i class='flaticon-tick'></i> Bot & Digital Assistants</li>
                            <li><i class='flaticon-tick'></i> Customer Service</li>
                            <li><i class='flaticon-tick'></i> Up to 3300 Subscribers</li>
                            <li><i class='flaticon-tick'></i> Unlimited Broadcasts</li>
                            <li><i class='flaticon-tick'></i> Landing Pages & Web Widgets</li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                    <div class="single-pricing-box">
                        <div class="pricing-header">
                            <h3>Premium</h3>
                            <p>Get your business up <br>and running</p>
                        </div>
                        <div class="price">
                            $179 <span>/m</span>
                        </div>
                        <div class="buy-btn">
                            <a routerLink="/" class="btn btn-primary">Start 6 Days Free Trial</a>
                        </div>
                        <ul class="pricing-features">
                            <li><i class='flaticon-tick'></i> Drag & Drop Page Builder</li>
                            <li><i class='flaticon-tick'></i> Lead Generation & Sales</li>
                            <li><i class='flaticon-tick'></i> Bot & Digital Assistants</li>
                            <li><i class='flaticon-tick'></i> Customer Service</li>
                            <li><i class='flaticon-tick'></i> Up to 10000 Subscribers</li>
                            <li><i class='flaticon-tick'></i> Unlimited Broadcasts</li>
                            <li><i class='flaticon-tick'></i> Landing Pages & Web Widgets</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="tabs-item">
            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-pricing-box">
                        <div class="pricing-header">
                            <h3>Free</h3>
                            <p>Get your business up <br>and running</p>
                        </div>
                        <div class="price">
                            $0 <span>/y</span>
                        </div>
                        <div class="buy-btn">
                            <a routerLink="/" class="btn btn-primary">Get Started Free</a>
                        </div>
                        <ul class="pricing-features">
                            <li><i class='flaticon-tick'></i> Drag & Drop Page Builder</li>
                            <li><i class='flaticon-tick'></i> Lead Generation & Sales</li>
                            <li><i class='flaticon-tick'></i> Bot & Digital Assistants</li>
                            <li><i class='flaticon-tick'></i> Customer Service</li>
                            <li><i class='flaticon-tick'></i> Up to 1000 Subscribers</li>
                            <li><i class='flaticon-tick'></i> Unlimited Broadcasts</li>
                            <li><i class='flaticon-tick'></i> Landing Pages & Web Widgets</li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-pricing-box active">
                        <div class="pricing-header">
                            <h3>Pro</h3>
                            <p>Get your business up <br>and running</p>
                        </div>
                        <div class="price">
                            $249 <span>/y</span>
                        </div>
                        <div class="buy-btn">
                            <a routerLink="/" class="btn btn-primary">Start 3 Days Free Trial</a>
                        </div>
                        <ul class="pricing-features">
                            <li><i class='flaticon-tick'></i> Drag & Drop Page Builder</li>
                            <li><i class='flaticon-tick'></i> Lead Generation & Sales</li>
                            <li><i class='flaticon-tick'></i> Bot & Digital Assistants</li>
                            <li><i class='flaticon-tick'></i> Customer Service</li>
                            <li><i class='flaticon-tick'></i> Up to 3300 Subscribers</li>
                            <li><i class='flaticon-tick'></i> Unlimited Broadcasts</li>
                            <li><i class='flaticon-tick'></i> Landing Pages & Web Widgets</li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                    <div class="single-pricing-box">
                        <div class="pricing-header">
                            <h3>Premium</h3>
                            <p>Get your business up <br>and running</p>
                        </div>
                        <div class="price">
                            $279 <span>/y</span>
                        </div>
                        <div class="buy-btn">
                            <a routerLink="/" class="btn btn-primary">Start 6 Days Free Trial</a>
                        </div>
                        <ul class="pricing-features">
                            <li><i class='flaticon-tick'></i> Drag & Drop Page Builder</li>
                            <li><i class='flaticon-tick'></i> Lead Generation & Sales</li>
                            <li><i class='flaticon-tick'></i> Bot & Digital Assistants</li>
                            <li><i class='flaticon-tick'></i> Customer Service</li>
                            <li><i class='flaticon-tick'></i> Up to 10000 Subscribers</li>
                            <li><i class='flaticon-tick'></i> Unlimited Broadcasts</li>
                            <li><i class='flaticon-tick'></i> Landing Pages & Web Widgets</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>