import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-free-trial-area',
  templateUrl: './free-trial-area.component.html',
  styleUrls: ['./free-trial-area.component.scss']
})
export class FreeTrialAreaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
