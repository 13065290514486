<!-- Start Topbar Wrap Area -->
<div class="topbar-wrap-area d-none" [ngClass]="{'d-block': router?.url === '/home-6' || router?.url === '/home-7'}">
    <div class="container-fluid">
        <div class="row align-items-center justify-content-center">
            <div class="col-lg-3 col-md-3">
                <div class="topbar-content">
                    <span>Welcome to Advansis Pay. Need Help? <a routerLink="/contact">Contact Us</a></span>
                </div>
            </div>
            <div class="col-lg-9 col-md-9">
                <ul class="topbar-action-list">
                    <li>
                        <i class="fa-solid fa-location-pin"></i> 2750 Quadra Street Victoria, Canada
                    </li>
                    <li>
                        <i class="fa-solid fa-phone"></i> <a href="tel:233244588584">+44 587 154756</a>
                    </li>
                    <li>
                        <i class="fa-solid fa-envelope"></i> <a
                        href="mailto:sparsity&#64;gmail.com">info&#64;advansistechnologies.com</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
<!-- End Topbar Wrap Area -->

<!-- Start Navbar Area -->
<div class="{{navClass}}" [ngClass]="{'p-relative': router?.url === '/home-5' || router?.url === '/home-8'}">
    <div class="kirin-nav">
        <div class="{{navContainer}}">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/">
                    <!-- <img src="{{logo}}" class="main-logo" alt="logo">
                    <img src="assets/img/black-logo.png" class="optional-logo" alt="logo"> -->
                    <span>Advansis Pay</span>
                </a>

                <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>

                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                        <li class="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                            <a routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                               class="nav-link">Home
                            </a>
                        </li>
                        <li class="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                            <a routerLink="/about-us" routerLinkActive="active" class="nav-link">About Us</a>
                        </li>
                        <li class="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                            <a routerLink="/dashboard" routerLinkActive="active"
                               [routerLinkActiveOptions]="{exact: true}" class="nav-link">Dashboard
                            </a>
                        </li>
                        <li class="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                            <a routerLink="/apps" routerLinkActive="active" class="nav-link">Apps</a>
                        </li>
                        <li class="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                            <a routerLink="/airtime-topup" routerLinkActive="active" class="nav-link">Airtime</a>
                        </li>
                        <li class="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                            <a routerLink="/internet-data" routerLinkActive="active" class="nav-link">Internet
                                Bundle
                            </a>
                        </li>
                        <li class="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                            <a routerLink="/partners" routerLinkActive="active" class="nav-link">Partners</a>
                        </li>

                        <li class="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                            <a routerLink="/contact" routerLinkActive="active" class="nav-link">Contact</a>
                        </li>
                    </ul>

                    <div class="others-options">
                        <div class="option-item"><i class="search-btn flaticon-search"></i>
                            <i class="close-btn fas fa-times"></i>
                            <div class="search-overlay search-popup">
                                <div class='search-box'>
                                    <form class="search-form">
                                        <input class="search-input" name="search" placeholder="Search" type="text">
                                        <button class="search-button" type="submit"><i
                                            class="fas fa-search"></i>
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>

                        <a routerLink="/contact" class="btn btn-primary">Register</a>
                    </div>
                </div>
            </nav>
        </div>
    </div>
</div>
<!-- End Navbar Area -->
