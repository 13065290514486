<!-- Start Page Title Area -->
<div class="page-title-area item-bg2">
    <div class="container">
        <div class="page-title-content">
            <h2>Projects Details</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Projects Details</li>
            </ul>
        </div>
    </div>

    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start project Details Area -->
<div class="projects-details-area ptb-110">
    <div class="container">
        <div class="projects-details">
            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="projects-details-image">
                        <img src="assets/img/projects-img1.jpg" alt="image">
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="projects-details-image">
                        <img src="assets/img/projects-img2.jpg" alt="image">
                    </div>
                </div>

                <div class="col-lg-4 col-md-12 col-sm-12">
                    <div class="projects-details-info">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <ul>
                                    <li><span>Customer Name:</span> Steven Smith</li>
                                    <li><span>Category:</span> Design, Idea</li>
                                    <li><span>Start Date:</span> February 20, 2023</li>
                                    <li><span>End Date:</span> February 28, 2023</li>
                                    <li><span>Status:</span> Finished</li>
                                    <li><span>Demo Link:</span> <a href="#" target="_blank">www.kirin.com</a></li>
                                    <li><span>Tags:</span> App, Design, Web, Dev</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="projects-details-desc">
                <h3>Incredible Infrastructure</h3>
                <p>Lorem ipsum dolor sit amet, conse cte tuer adipiscing elit, sed diam no nu m nibhie eui smod. Facil isis atve eros et accumsan etiu sto odi dignis sim qui blandit praesen lup ta de er. At molestiae appellantur pro. Vis wisi oportere per ic ula ad, ei latine prop riae na, mea cu purto debitis. Primis nost rud no eos, no impedit dissenti as mea, ea vide labor amus neglegentur vix. Ancillae intellegat vix et. Sit causae laoreet nolu ise.</p>
                <p>Ad po exerci nusquam eos te. Cu altera expet enda qui, munere oblique theo phrastu ea vix. Ne nec modus civibus modera tius, sit ei lorem doctus. Ne docen di verterem reformidans eos. Cu altera expetenda qui, munere oblique theophr astus ea vix modus civiu mod eratius.</p>
                <blockquote class="wp-block-quote">
                    <p class="mb-0">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                </blockquote>
                <p>Lorem ipsum dolor sit amet, conse cte tuer adipiscing elit, sed diam no nu m nibhie eui smod. Facil isis atve eros et accumsan etiu sto odi dignis sim qui blandit praesen lup ta de er. At molestiae appellantur pro. Vis wisi oportere per ic ula ad, ei latine prop riae na, mea cu purto debitis.</p>
                <p>Nost rud no eos, no impedit dissenti as mea, ea vide labor amus neglegentur vix. Ancillae intellegat vix et. Sit causae laoreet nolu ise. Ad po exerci nusquam eos te. Cu altera expet enda qui, munere oblique theo phrastu ea vix. Ne nec modus civibus modera tius, sit ei lorem doctus. Ne docen di verterem reformidans eos. Cu altera expetenda qui, munere oblique theophr astus ea vix modus civiu mod eratius.</p>
            </div>
        </div>
    </div>
</div>
<!-- End project Details Area -->