<!-- Start Page Title Area -->
<div class="page-title-area item-bg3">
    <div class="container">
        <div class="page-title-content">
            <h2>Contact</h2>
            <!-- <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Contact</li>
            </ul> -->
        </div>
    </div>

    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Contact Area -->
<section class="contact-area ptb-110">
    <div class="container">
        <div class="section-title">
            <span>Message Us</span>
            <h2>Drop us Message for any Query</h2>
            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p> -->
        </div>

        <div class="contact-form">
            <div class="row align-items-center">
                <div class="col-lg-5 col-md-12">
                    <div class="contact-image">
                        <img src="assets/img/contact.png" alt="image">
                    </div>
                </div>

                <div class="col-lg-7 col-md-12">
                    <form id="contactForm">
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <input type="text" name="name" id="name" class="form-control" required placeholder="Name">
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <input type="email" name="email" id="email" class="form-control" required placeholder="Email">
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <input type="text" name="phone_number" id="phone_number" required class="form-control" placeholder="Phone">
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <input type="text" name="msg_subject" id="msg_subject" class="form-control" placeholder="Subject">
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <textarea name="message" class="form-control" id="message" cols="30" rows="5" placeholder="Your Message"></textarea>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="btn btn-primary">Send Message</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <div class="contact-info">
            <div class="contact-info-content">
                <h3>Contact us by Phone Number or Email Address</h3>
                <h2>
                    <a href="tel:+0881306298615">+088 130 629 8615</a>
                    <span>OR</span>
                    <a href="mailto:info&#64;advansistechnologies.com">info&#64;advansistechnologies.com</a>
                </h2>
                <ul class="social">
                    <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                    <li><a href="#" target="_blank"><i class="fab fa-youtube"></i></a></li>
                    <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                    <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                    <li><a href="#" target="_blank"><i class="fab fa-instagram"></i></a></li>
                </ul>
            </div>
        </div>
    </div>
</section>
<!-- End Contact Area -->
