<!-- Start Main Banner Area -->
<div class="main-banner">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container-fluid">
                <div class="main-banner-content">
                    <h1>World’s Leading Machine Learning Company</h1>
                    <p>ML today can supply needful of help, information, and positive experience of maintaining intimacy with customers. Eventually, chatbot ideas bring a pleasant experience of all these qualities into the conversation.</p>
                    <div class="btn-box">
                        <a routerLink="/contact" class="btn btn-primary">Schedule a Demo</a>
                        <a routerLink="/contact" class="optional-btn">Get Started Free</a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="featured-services-area">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="single-featured-services-box">
                        <div class="icon">
                            <i class="flaticon-robot"></i>
                        </div>
                        <h3><a routerLink="/service-details">Robotic Process Automation</a></h3>
                        <p>Lorem ipsum dolor consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore. Ut enim ad minim veniam.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-featured-services-box active">
                        <div class="icon">
                            <i class="flaticon-artificial-intelligence"></i>
                        </div>
                        <h3><a routerLink="/service-details">Cognitive Automation</a></h3>
                        <p>Lorem ipsum dolor consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore. Ut enim ad minim veniam.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                    <div class="single-featured-services-box">
                        <div class="icon">
                            <i class="flaticon-machine-learning"></i>
                        </div>
                        <h3><a routerLink="/service-details">Cognitive Engagement</a></h3>
                        <p>Lorem ipsum dolor consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore. Ut enim ad minim veniam.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Main Banner Area -->

<app-about-area></app-about-area>

<!-- Start Services Area -->
<section class="services-area bg-f2f6f9 ptb-110">
    <div class="container">
        <div class="section-title">
            <h2>We Offer Professional Solutions</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-income"></i>
                    </div>
                    <h3><a routerLink="/service-details">Data Analysts</a></h3>
                    <p>It is a long fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-automatic"></i>
                    </div>
                    <h3><a routerLink="/service-details">Automatic Optimization</a></h3>
                    <p>It is a long fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-locked"></i>
                    </div>
                    <h3><a routerLink="/service-details">Security & Surveillance</a></h3>
                    <p>It is a long fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-molecular"></i>
                    </div>
                    <h3><a routerLink="/service-details">Healthcare & Manufacturing</a></h3>
                    <p>It is a long fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-gear"></i>
                    </div>
                    <h3><a routerLink="/service-details">Software Engineers</a></h3>
                    <p>It is a long fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-ceo"></i>
                    </div>
                    <h3><a routerLink="/service-details">IT Professionals</a></h3>
                    <p>It is a long fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                </div>
            </div>
        </div>
    </div>

    <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.png" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape/shape4.svg" alt="image"></div>
    <div class="shape-img5"><img src="assets/img/shape/shape5.svg" alt="image"></div>
    <div class="shape-img7"><img src="assets/img/shape/shape3.png" alt="image"></div>
    <div class="dot-shape1"><img src="assets/img/shape/dot1.png" alt="image"></div>
    <div class="dot-shape2"><img src="assets/img/shape/dot3.png" alt="image"></div>
    <div class="dot-shape4"><img src="assets/img/shape/dot4.png" alt="image"></div>
    <div class="dot-shape5"><img src="assets/img/shape/dot5.png" alt="image"></div>
    <div class="dot-shape6"><img src="assets/img/shape/dot6.png" alt="image"></div>
</section>
<!-- End Services Area -->

<!-- Start Webinar Area -->
<section class="webinar-area">
    <div class="row m-0">
        <div class="col-lg-6 p-0">
            <div class="webinar-content">
                <h2>Check out our latest webinar</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humor, or randomized words which don't look even slightly believable. If you are going to use a passage.</p>
                <a routerLink="/about" class="btn btn-primary">Watch More</a>
            </div>
        </div>

        <div class="col-lg-6 p-0">
            <div class="webinar-video-image">
                <img src="assets/img/woman.jpg" alt="image">
                <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn popup-youtube"><i class="flaticon-play-button"></i></a>
            </div>
        </div>
    </div>
</section>
<!-- End Webinar Area -->

<app-partner-area></app-partner-area>

<app-feedback></app-feedback>

<!-- Start Pricing Area -->
<section class="pricing-area ptb-110 bg-fafafa">
    <div class="container">
        <div class="section-title">
            <h2>Our Pricing Plan</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <app-pricing-plan></app-pricing-plan>
    </div>

    <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.png" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape/shape4.svg" alt="image"></div>
    <div class="shape-img5"><img src="assets/img/shape/shape5.svg" alt="image"></div>
    <div class="shape-img7"><img src="assets/img/shape/shape3.png" alt="image"></div>
    <div class="dot-shape1"><img src="assets/img/shape/dot1.png" alt="image"></div>
    <div class="dot-shape2"><img src="assets/img/shape/dot3.png" alt="image"></div>
    <div class="dot-shape4"><img src="assets/img/shape/dot4.png" alt="image"></div>
    <div class="dot-shape5"><img src="assets/img/shape/dot5.png" alt="image"></div>
    <div class="dot-shape6"><img src="assets/img/shape/dot6.png" alt="image"></div>
</section>
<!-- End Pricing Area -->

<!-- Start Blog Area -->
<section class="blog-area ptb-110">
    <div class="container">
        <div class="section-title">
            <h2>Our Recent Story</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="entry-thumbnail">
                        <a routerLink="/blog-details"><img src="assets/img/blog/blog1.jpg" alt="image"></a>
                    </div>
                    <div class="entry-post-content">
                        <div class="entry-meta">
                            <ul>
                                <li><a routerLink="/">Admin</a></li>
                                <li>August 15, 2023</li>
                            </ul>
                        </div>
                        <h3><a routerLink="/blog-details">Making a Large Scale AI Platform for Facing Upcoming Marketing Challenges!</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod...</p>
                        <a routerLink="/blog-details" class="learn-more-btn">Read Story <i class="flaticon-add"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="entry-thumbnail">
                        <a routerLink="/blog-details"><img src="assets/img/blog/blog2.jpg" alt="image"></a>
                    </div>
                    <div class="entry-post-content">
                        <div class="entry-meta">
                            <ul>
                                <li><a routerLink="/">Admin</a></li>
                                <li>August 18, 2023</li>
                            </ul>
                        </div>
                        <h3><a routerLink="/blog-details">Machine Learning for Smart Analysis and Beter Detection!</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod...</p>
                        <a routerLink="/blog-details" class="learn-more-btn">Read Story <i class="flaticon-add"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="single-blog-post">
                    <div class="entry-thumbnail">
                        <a routerLink="/blog-details"><img src="assets/img/blog/blog3.jpg" alt="image"></a>
                    </div>
                    <div class="entry-post-content">
                        <div class="entry-meta">
                            <ul>
                                <li><a routerLink="/">Admin</a></li>
                                <li>August 15, 2023</li>
                            </ul>
                        </div>
                        <h3><a routerLink="/blog-details">Challenging Areas of a Machine Learning Startup</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod...</p>
                        <a routerLink="/blog-details" class="learn-more-btn">Read Story <i class="flaticon-add"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.png" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape/shape4.svg" alt="image"></div>
    <div class="shape-img5"><img src="assets/img/shape/shape5.svg" alt="image"></div>
    <div class="shape-img7"><img src="assets/img/shape/shape3.png" alt="image"></div>
    <div class="dot-shape1"><img src="assets/img/shape/dot1.png" alt="image"></div>
    <div class="dot-shape2"><img src="assets/img/shape/dot3.png" alt="image"></div>
    <div class="dot-shape4"><img src="assets/img/shape/dot4.png" alt="image"></div>
    <div class="dot-shape5"><img src="assets/img/shape/dot5.png" alt="image"></div>
    <div class="dot-shape6"><img src="assets/img/shape/dot6.png" alt="image"></div>
</section>
<!-- End Blog Area -->

<app-free-trial-area></app-free-trial-area>