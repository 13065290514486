<!-- Start Page Title Area -->
<div class="page-title-area item-bg1">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <!-- <div class="icon">
                        <i class="flaticon-income"></i>
                    </div> -->
                    <h3>
                        <a class="" data-bs-toggle="modal" data-bs-target="#interModal">
                            International
                        </a>
                    </h3>
                    <!-- <p>
                        It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.
                    </p> -->
                    <!-- Button trigger modal -->

                </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <!-- <div class="icon">
                        <i class="flaticon-automatic"></i>
                    </div> -->
                    <h3>
                        <a data-bs-toggle="modal" data-bs-target="#localModal" data-bs-whatever="@mdo">
                            Ghana
                        </a>
                    </h3>

                </div>
            </div>

        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Services Area -->
<section class="services-area bg-f2f6f9 ptb-110">
    <div class="container">

    </div>

    <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.png" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape/shape4.svg" alt="image"></div>
    <div class="shape-img5"><img src="assets/img/shape/shape5.svg" alt="image"></div>
    <div class="shape-img7"><img src="assets/img/shape/shape3.png" alt="image"></div>
    <div class="dot-shape1"><img src="assets/img/shape/dot1.png" alt="image"></div>
    <div class="dot-shape2"><img src="assets/img/shape/dot3.png" alt="image"></div>
    <div class="dot-shape4"><img src="assets/img/shape/dot4.png" alt="image"></div>
    <div class="dot-shape5"><img src="assets/img/shape/dot5.png" alt="image"></div>
    <div class="dot-shape6"><img src="assets/img/shape/dot6.png" alt="image"></div>
</section>
<!-- End Services Area -->


<section id="modal">
    <!-- Modal International -->
    <div class="modal fade" id="interModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Recharge Airtime</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div id="globalAirForm" class="modal-body">
                    <form [formGroup]="globalAirForm" (ngSubmit)="globalAirFormSubmit(globalAirForm.value)">
                        
                        <div class="mb-3">
                            <label for="recipient-name" class="col-form-label">Countries:</label>
                            <select class="form-select" id="recipientCountryCode" aria-label="countryCode" formControlName="recipientCountryCode">
                                <option selected>Select Country</option>
                                <option *ngFor="let country of countries" [ngValue]="country">
                                    {{country.name }} - {{country.continent}} -> {{country.currencyName}}
                                    ({{country.currencySymbol}})
                                </option>
                            </select>
                        </div>

                        <div class="mb-3">
                            <label for="recipient-name" class="col-form-label">
                                Recipient number (Auto Detect):
                            </label>
                            <input 
                                type="text" 
                                class="form-control"
                                formControlName="recipientNumber"
                                id="recipientNumber"
                                >
                        </div>
                        <div class="mb-3">
                            <label for="message-text" class="col-form-label">Amount:</label>
                            <input 
                                type="text"
                                class="form-control"
                                formControlName="amount"
                                id="Amount"
                                >
        
                        </div>

                        <div class="modal-footer">
                            <button type="submit" class="btn btn-primary">Send</button>
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        </div>
                    </form>
                </div>
          
            </div>
        </div>
    </div>

    <!-- Modal Local -->
    <div class="modal fade" id="localModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Recharge Airtime</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <form *ngIf="airtimeForm" [formGroup]="airtimeForm" (ngSubmit)="topupFormSubmit(airtimeForm.value)">
                        
                        <div class="mb-3">
                            <label for="recipient-name" class="col-form-label">Network Operator:</label>
                            <select class="form-select" id="network" aria-label="network" formControlName="network">
                                <option value="0" selected>Auto Detect</option>
                                <option value="4">MTN Ghana</option>
                                <option value="6">Telecel Ghana</option>
                                <option value="1">AirtelTigo Ghana</option>
                                <option value="3">Glo Ghana</option>
                            </select>
                        </div>

                        <div class="mb-3">
                            <label for="recipientNumber" class="col-form-label">Recipient Number:</label>
                            <input 
                                type="text" 
                                class="form-control"
                                id="recipientNumber"
                                formControlName="recipientNumber"
                                placeholder="e.g. 01234567890"
                                required
                                >
                        </div>
                        <div class="mb-3">
                            <input 
                                type="number"
                                class="form-control"
                                id="amount"
                                formControlName="amount"
                                placeholder="Amount(₵): 1 - 50"
                                required
                                >
                        </div>

                        <div class="modal-footer">
                            <button type="submit" class="btn btn-primary">Send</button>
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        </div>
                    </form>
                </div>
             
            </div>
        </div>
    </div>

</section>