<!-- Start Main Banner Area -->
<div class="hero-banner">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="hero-banner-content">
                    <h2>World’s Leading Airtime & Data Remittance Platform!</h2>
                    <p>
                        Recharge your phone with airtime or internet data effortlessly,
                        no matter where you are with the global coverage Advansis Technologies Apps: Web and Mobile.
                        Download now from the Google Play Store or Apple App Store!
                    </p>
                    <div class="btn-box">
                        <a routerLink="/contact" class="btn btn-primary">Google Play</a>
                        &nbsp;
                        <a routerLink="/" class="btn btn-primary">App Store</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="hero-banner-image">
                    <img src="assets/img/advans-phone-1.png" class="wow animate__animated animate__zoomInUp"
                        data-wow-delay="1s" alt="image">
                </div>
            </div>

        </div>
    </div>

    <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.png" alt="image"></div>
    <div class="shape-img5"><img src="assets/img/shape/shape5.svg" alt="image"></div>
    <div class="dot-shape1"><img src="assets/img/shape/dot1.png" alt="image"></div>
    <div class="dot-shape2"><img src="assets/img/shape/dot3.png" alt="image"></div>
</div>
<!-- End Main Banner Area -->

<!-- Start Projects Area -->
<section class="projects-area pt-0 ptb-110">
    <div class="container">
        <div class="section-title">
            <h2>Our Featured Solutions</h2>
        </div>
    </div>

    <div class="container-fluid">
        <div class="projects-slides owl-carousel owl-theme">
            <div class="single-projects-box">
                <img src="assets/img/feature/feature-global-1.jpg" alt="image">
                <div class="plus-icon">
                    <a routerLink="/airtime-topup">
                        <span></span>
                    </a>
                </div>
            </div>

            <div class="single-projects-box">
                <img src="assets/img/feature/feature-airtime-1.jpg" alt="image">
                <div class="plus-icon">
                    <a routerLink="/airtime-topup">
                        <span></span>
                    </a>
                </div>
            </div>

            <div class="single-projects-box">
                <img src="assets/img/feature/feature-internet-1.jpg" alt="image">
                <div class="plus-icon">
                    <a routerLink="/internet-data">
                        <span></span>
                    </a>
                </div>
            </div>

            <div class="single-projects-box">
                <img src="assets/img/feature/feature-advans-rewards.jpg" alt="image">
                <div class="plus-icon">
                    <a routerLink="/rewards">
                        <span></span>
                    </a>
                </div>
            </div>

            <div class="single-projects-box">
                <img src="assets/img/feature/feature-partners-1.jpg" alt="image">
                <div class="plus-icon">
                    <a routerLink="/partners">
                        <span></span>
                    </a>
                </div>
            </div>
        </div>
    </div>

    <div class="circle-shape1"><img src="assets/img/projects-shape.png" alt="image"></div>
</section>
<!-- End Projects Area -->

<!-- Start Top Featured Area -->
<div class="top-featured-area pt-100 pb-100">
    <div class="container">
        <div class="section-title">
            <h2>How to recharge Airtime & Internet Data Bundle</h2>
        </div>

        <div class="row justify-content-center">
            <div class="col-lg-3 col-sm-6">
                <div class="top-featured-card text-center">
                    <div class="image-icon">
                        <img src="assets/img/home-six/featured/icon1.png" alt="icon">
                    </div>
                    <h3>
                        <a href="single-services.html">1. Create Account</a>
                    </h3>
                    <!-- <a href="single-services.html" class="learn-btn">Learn More</a> -->
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="top-featured-card text-center">
                    <div class="image-icon">
                        <img src="assets/img/home-six/featured/icon2.png" alt="icon">
                    </div>
                    <h3>
                        <a href="single-services.html">2. Select Menu</a>
                    </h3>
                    <!-- <a href="single-services.html" class="learn-btn">Learn More</a> -->
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="top-featured-card text-center">
                    <div class="image-icon">
                        <img src="assets/img/home-six/featured/icon3.png" alt="icon">
                    </div>
                    <h3>
                        <a href="single-services.html">3. Enter Number</a>
                    </h3>
                    <!-- <a href="single-services.html" class="learn-btn">Learn More</a> -->
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="top-featured-card text-center">
                    <div class="image-icon">
                        <img src="assets/img/home-six/featured/icon4.png" alt="icon">
                    </div>
                    <h3>
                        <a href="single-services.html">4. Make Payment</a>
                    </h3>
                    <!-- <a href="single-services.html" class="learn-btn">Learn More</a> -->
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="top-featured-btn">
                    <a routerLink="/" class="btn btn-primary">Get Started Free</a>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Top Featured Area -->

<!-- Start Feedback Area -->
<section class="feedback-area">
    <div class="container ptb-95">
        <div class="section-title">
            <h2>What Clients Say About Us</h2>
        </div>
    </div>

    <div class="feedback-slides owl-carousel owl-theme">
        <div class="row m-0">
            <div class="col-lg-6 col-md-12 p-0">
                <div class="feedback-item">
                    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered
                        alteration in some form, by injected humor predefined words which don't look.</p>

                    <div class="client-info">
                        <div class="client-pic">
                            <img src="assets/img/client/client-4.jpg" alt="image">
                        </div>
                        <h3>Filip Luis</h3>
                        <span>Founder & CEO</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12 p-0">
                <div class="client-image bg1">
                    <img src="assets/img/client/client-4.jpg" alt="image">
                </div>
            </div>
        </div>

        <div class="row m-0">
            <div class="col-lg-6 col-md-12 p-0">
                <div class="feedback-item">
                    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered
                        alteration in some form, by injected humor predefined words which don't look.</p>

                    <div class="client-info">
                        <div class="client-pic">
                            <img src="assets/img/client/client-4.jpg" alt="image">
                        </div>
                        <h3>Sarah Taylor</h3>
                        <span>Designer</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12 p-0">
                <div class="client-image bg2">
                    <img src="assets/img/client/client-4.jpg" alt="image">
                </div>
            </div>
        </div>

        <div class="row m-0">
            <div class="col-lg-6 col-md-12 p-0">
                <div class="feedback-item">
                    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered
                        alteration in some form, by injected humor predefined words which don't look.</p>

                    <div class="client-info">
                        <div class="client-pic">
                            <img src="assets/img/client/client-4.jpg" alt="image">
                        </div>
                        <h3>Meg Lanning</h3>
                        <span>Support</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12 p-0">
                <div class="client-image bg3">
                    <img src="assets/img/client/client-4.jpg" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Feedback Area -->

<!-- Start Partner Area -->
<section class="partner-section ptb-110">
    <div class="container">
        <div class="partner-slides owl-carousel owl-theme">
            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner/partner1.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner/partner2.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner/partner3.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner/partner1.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner/partner2.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner/partner3.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner/partner1.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner/partner2.png" alt="image">
                </a>
            </div>

            <div class="single-partner-item">
                <a href="#">
                    <img src="assets/img/partner/partner3.png" alt="image">
                </a>
            </div>
        </div>
    </div>

    <div class="dot-shape1"><img src="assets/img/shape/dot1.png" alt="image"></div>
    <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape/shape4.svg" alt="image"></div>
    <div class="shape-img5"><img src="assets/img/shape/shape5.svg" alt="image"></div>
</section>
<!-- End Partner Area -->