import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-partner-area',
  templateUrl: './partner-area.component.html',
  styleUrls: ['./partner-area.component.scss']
})
export class PartnerAreaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
