<!-- Start Page Title Area -->
<div class="page-title-area item-bg1">
    <div class="container">
        <div class="page-title-content">
            <h2>Projects</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Projects</li>
            </ul>
        </div>
    </div>

    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Projects Area -->
<section class="projects-area ptb-110">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-projects-box">
                    <img src="assets/img/projects-img1.jpg" alt="image">
                    <div class="plus-icon">
                        <a routerLink="/project-details">
                            <span></span>
                        </a>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-projects-box">
                    <img src="assets/img/projects-img2.jpg" alt="image">
                    <div class="plus-icon">
                        <a routerLink="/project-details">
                            <span></span>
                        </a>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-projects-box">
                    <img src="assets/img/projects-img3.jpg" alt="image">
                    <div class="plus-icon">
                        <a routerLink="/project-details">
                            <span></span>
                        </a>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-projects-box">
                    <img src="assets/img/projects-img4.jpg" alt="image">
                    <div class="plus-icon">
                        <a routerLink="/project-details">
                            <span></span>
                        </a>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-projects-box">
                    <img src="assets/img/projects-img5.jpg" alt="image">
                    <div class="plus-icon">
                        <a routerLink="/project-details">
                            <span></span>
                        </a>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-projects-box">
                    <img src="assets/img/projects-img6.jpg" alt="image">
                    <div class="plus-icon">
                        <a routerLink="/project-details">
                            <span></span>
                        </a>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-projects-box">
                    <img src="assets/img/projects-img7.jpg" alt="image">
                    <div class="plus-icon">
                        <a routerLink="/project-details">
                            <span></span>
                        </a>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-projects-box">
                    <img src="assets/img/projects-img8.jpg" alt="image">
                    <div class="plus-icon">
                        <a routerLink="/project-details">
                            <span></span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="circle-shape1"><img src="assets/img/projects-shape.png" alt="image"></div>
</section>
<!-- End Projects Area -->