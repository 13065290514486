<!-- Start Page Title Area -->
<div class="page-title-area item-bg1">
    <div class="container">
        <div class="page-title-content">
            <h2>About Us</h2>
            <!-- <ul>
                <li><a routerLink="/">Home</a></li>
                <li>About Us</li>
            </ul> -->
        </div>
    </div>

    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start About Area -->
<section class="about-area ptb-110">
    <div class="container">
        <!-- about us -->
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="services-details-image wow animate__animated animate__fadeInUp">
                    <img src="assets/img/services-details/services-details1.jpg" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <h2>Advansis Technologies</h2>
                    <span>
                        <h5>
                            Welcome to Advansis Technologies, your go-to for seamless remittance services.
                            We specialize in providing advanced web and mobile platforms that allow users to
                            effortlessly purchase airtime and internet data bundles from anywhere in the world.
                            Our services are tailored to offer local convenience and ease, particularly for our valued
                            customers in Ghana.
                        </h5>
                    </span>
                    <span>
                        <h5>
                            Local Convenience in Ghana: We understand the unique needs of our users in Ghana,
                            and we provide a localized experience that simplifies the purchase of airtime and data
                            bundles.
                            You can enjoy the convenience of using local payment methods and currencies for smooth
                            transactions
                        </h5>
                    </span>
                    <a routerLink="/apps" class="btn btn-primary">Discover More</a>
                </div>
            </div>
        </div>

        <!-- overview -->
        <div class="services-details-overview">
            <div class="services-details-desc">
                <h3>Key Features That Set Us Apart</h3>
                <p>
                    We offer a robust remittance platform that caters to your international money transfer needs,
                    while also providing you with services to enhance your daily life in Ghana.
                </p>

                <div class="services-details-accordion">
                    <ul class="accordion">
                        <li class="accordion-item active">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="fas fa-plus"></i>
                                Global Reach, Local Convenience
                            </a>
                            <p class="accordion-content">
                                Our platforms support transactions worldwide,
                                allowing users to buy airtime and data bundles for any country.
                                In Ghana, our services are tailored to ensure ease of access and local convenience.
                            </p>
                        </li>

                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="fas fa-plus"></i>
                                User-Friendly Platforms?
                            </a>
                            <p class="accordion-content">
                                Security is our top priority.
                                Advansis Technologies employs advanced encryption and security measures to protect your
                                transactions and personal information,
                                giving you peace of mind with every purchase.
                            </p>
                        </li>

                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="fas fa-plus"></i>
                                Instant Delivery 
                            </a>
                            <p class="accordion-content">
                                We understand the importance of timely communication. Our platforms guarantee instant
                                delivery of airtime and data bundles,
                                so you’re always connected when you need it most.
                            </p>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="services-details-image wow animate__animated animate__fadeInUp">
                <img src="assets/img/services-details/services-details2.jpg" alt="image">
            </div>
        </div>
    </div>

    <div class="shape-img1"><img src="assets/img/shape/shape1.png" alt="image"></div>
    <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.png" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape/shape4.svg" alt="image"></div>
    <div class="shape-img5"><img src="assets/img/shape/shape5.svg" alt="image"></div>
 
</section>
<!-- End About Area -->


<!-- Start Team Area -->
<section class="team-area ptb-110">
    <div class="container">
        <div class="section-title">
            <h2>Meet Our Team</h2>
        </div>

        <div class="row">

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-team-box">
                    <div class="image">
                        <img src="assets/img/team/team2.jpg" alt="team">
                        <div class="social">
                            <a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a>
                            <a href="#" target="_blank"><i class="fab fa-twitter"></i></a>
                            <a href="#" target="_blank"><i class="fab fa-instagram"></i></a>
                        </div>
                    </div>
                    <div class="content">
                        <h3>Kofi Kyei</h3>
                        <span>CEO</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-team-box">
                    <div class="image">
                        <img src="assets/img/team/team1.jpg" alt="team">
                        <div class="social">
                            <a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a>
                            <a href="#" target="_blank"><i class="fab fa-twitter"></i></a>
                            <a href="#" target="_blank"><i class="fab fa-instagram"></i></a>
                        </div>
                    </div>
                    <div class="content">
                        <h3>Hanson Peprah</h3>
                        <span>Fullstalk Developer</span>
                    </div>
                </div>
            </div>

        
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-team-box">
                    <div class="image">
                        <img src="assets/img/team/team3.jpg" alt="team">
                        <div class="social">
                            <a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a>
                            <a href="#" target="_blank"><i class="fab fa-twitter"></i></a>
                            <a href="#" target="_blank"><i class="fab fa-instagram"></i></a>
                        </div>
                    </div>
                    <div class="content">
                        <h3>Steven Adai</h3>
                        <span>Web Developer</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-team-box">
                    <div class="image">
                        <img src="assets/img/team/team4.jpg" alt="team">
                        <div class="social">
                            <a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a>
                            <a href="#" target="_blank"><i class="fab fa-twitter"></i></a>
                            <a href="#" target="_blank"><i class="fab fa-instagram"></i></a>
                        </div>
                    </div>
                    <div class="content">
                        <h3>Sarah Osei</h3>
                        <span>Designer</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Team Area -->


<app-free-trial-area></app-free-trial-area>