<!-- Start Footer Area -->
<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <div class="logo">
                        <!-- <a routerLink="/">
                            <img src="assets/img/white-logo.png" alt="image">
                        </a> -->
                        <span> <h3>Advans Pay</h3></span>
                        <p>
                            Top-up your phone network or internet data anywhere in the world with 
                            Advansis Technologies convenient web and mobile applications.
                        </p>
                    </div>
                    <ul class="social">
                        <li><a href="#" target="_blank"><i class="flaticon-facebook-letter-logo"></i></a></li>
                        <li><a href="#" target="_blank"><i class="flaticon-twitter"></i></a></li>
                        <li><a href="#" target="_blank"><i class="flaticon-instagram-logo"></i></a></li>
                        <li><a href="#" target="_blank"><i class="flaticon-youtube-play-button"></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Services</h3>

                    <ul class="footer-services-list">
                        <li><a routerLink="/">Product Engineering</a></li>
                        <li><a routerLink="/">UX/UI Design</a></li>
                        <li><a routerLink="/">Big Data Analysis</a></li>
                        <li><a routerLink="/">Desktop Applications</a></li>
                        <li><a routerLink="/">Mobile Applications</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Quick Links</h3>

                    <ul class="quick-links-list">
                        <li><a routerLink="/">About Us</a></li>
                        <li><a routerLink="/">Blog</a></li>
                        <li><a routerLink="/">Contact</a></li>
                        <li><a routerLink="/">Support</a></li>
                        <li><a routerLink="/">Applications</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Contacts</h3>

                    <ul class="footer-contact-list">
                        <li><span>Address:</span>2750 Quadra Street <br /> Victoria, Canada</li>
                        <li><span>Email:</span> <a href="mailto:hello&#64;kirin.com">hello&#64;kirin.com</a></li>
                        <li><span>Phone:</span> <a href="tel:+44587154756">+44 587 154756</a></li>
                        <li><span>Fax:</span> <a href="tel:+44587154756">+44 785 4578964</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="copyright-area">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <p>
                        © Advans Pay is Proudly Owned by 
                        <a href="https://advansistechnologies.com/" target="_blank">Advansis Technologies Ghana</a>
                    </p>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-6">
                    <ul>
                        <li><a routerLink="/">Privacy Policy</a></li>
                        <li><a routerLink="/">Terms & Conditions</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="circle-map"><img src="assets/img/circle-map.png" alt="image"></div>

    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</footer>
<!-- End Footer Area -->

<div class="go-top"><i class="fas fa-arrow-up"></i><i class="fas fa-arrow-up"></i></div>
