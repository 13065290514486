import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-machine-learning-two',
  templateUrl: './machine-learning-two.component.html',
  styleUrls: ['./machine-learning-two.component.scss']
})
export class MachineLearningTwoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
