<!-- Start Partner Area -->
<section class="partner-area ptb-110 bg-f2f6f9">
    <div class="container">
        <div class="section-title">
            <h2>Featured Customers & Partners</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="customers-partner-list">
            <div class="partner-item">
                <a href="#">
                    <img src="assets/img/partner/partner1.png" alt="image">
                </a>
            </div>

            <div class="partner-item">
                <a href="#">
                    <img src="assets/img/partner/partner2.png" alt="image">
                </a>
            </div>

            <div class="partner-item">
                <a href="#">
                    <img src="assets/img/partner/partner3.png" alt="image">
                </a>
            </div>

            <div class="partner-item">
                <a href="#">
                    <img src="assets/img/partner/partner1.png" alt="image">
                </a>
            </div>

            <div class="partner-item">
                <a href="#">
                    <img src="assets/img/partner/partner2.png" alt="image">
                </a>
            </div>

            <div class="partner-item">
                <a href="#">
                    <img src="assets/img/partner/partner3.png" alt="image">
                </a>
            </div>

            <div class="partner-item">
                <a href="#">
                    <img src="assets/img/partner/partner1.png" alt="image">
                </a>
            </div>

            <div class="partner-item">
                <a href="#">
                    <img src="assets/img/partner/partner2.png" alt="image">
                </a>
            </div>

            <div class="partner-item">
                <a href="#">
                    <img src="assets/img/partner/partner3.png" alt="image">
                </a>
            </div>
        </div>
    </div>
</section>
<!-- End Partner Area -->
