import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment.prod';

@Injectable({
    providedIn: 'root'
})
export class PayswitchService {

    private awServer: string = environment.awServer;

    constructor(
        private httpClient: HttpClient
    ) {
    }

    public makePayment(mData: any): Observable<any> {
        return this.httpClient
            .post(`${this.awServer}/api/pscardpayment/inline`, mData)
            .pipe(
                tap((_res) => this.log(`paymentService: airtime credit`)),
                catchError(this.handleError('AirtimeService', []))
            );
    }


    public debitWalletPayswitch(mData: any): Observable<any> {
        return this.httpClient
            .post(`${this.awServer}/api/psmobilemoney/debitwallet`, mData)
            .pipe(
                tap((_res) => this.log(`PRYMO PAY RECEIVE MONEY =>`)),
                catchError(this.handleError('PaymentService', []))
            );
    }

    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
            console.error(error); // log to console instead
            this.log(`${operation} failed: ${error.message}`);

            return of(result as T);
        };
    }

    private log(message: string) {
        console.log(message);
    }
}
