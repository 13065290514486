import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class GlobalAirtimeService {
  private awServer: string = environment.awServer;

  constructor(
    private httpClient: HttpClient
  ) { }

  public makeAsyncAirtimeTopup(matData: any): Observable<any> {
    return this.httpClient
      .post<any>(`${this.awServer}/api/reload-airtime/recharge-async`, matData)
      .pipe(
        tap(_res => this.log(`AsyncAirtimeService: airtime recharge`)),
        catchError(this.handleError('AsyncAirtimeService', []))
      );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error); // log to console instead
      this.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }

  private log(message: string) {
    console.log(message);
  }
}
