<!-- Start Page Title Area -->
<div class="page-title-area item-bg2">
    <div class="container">
        <div class="page-title-content">
            <h2>Blog</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Blog</li>
            </ul>
        </div>
    </div>

    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Blog Area -->
<section class="blog-area ptb-110">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="entry-thumbnail">
                        <a routerLink="/blog-details"><img src="assets/img/blog/blog1.jpg" alt="image"></a>
                    </div>
                    <div class="entry-post-content">
                        <div class="entry-meta">
                            <ul>
                                <li><a routerLink="/">Admin</a></li>
                                <li>August 15, 2023</li>
                            </ul>
                        </div>
                        <h3><a routerLink="/blog-details">Making an AI Product with Focus on Simplicity of Automation</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod...</p>
                        <a routerLink="/blog-details" class="learn-more-btn">Read More <i class="flaticon-add"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="entry-thumbnail">
                        <a routerLink="/blog-details"><img src="assets/img/blog/blog2.jpg" alt="image"></a>
                    </div>
                    <div class="entry-post-content">
                        <div class="entry-meta">
                            <ul>
                                <li><a routerLink="/">Admin</a></li>
                                <li>August 18, 2023</li>
                            </ul>
                        </div>
                        <h3><a routerLink="/blog-details">Top 10 Best Practices Before Starting an AI Startup</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod...</p>
                        <a routerLink="/blog-details" class="learn-more-btn">Read More <i class="flaticon-add"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="entry-thumbnail">
                        <a routerLink="/blog-details"><img src="assets/img/blog/blog3.jpg" alt="image"></a>
                    </div>
                    <div class="entry-post-content">
                        <div class="entry-meta">
                            <ul>
                                <li><a routerLink="/">Admin</a></li>
                                <li>August 15, 2023</li>
                            </ul>
                        </div>
                        <h3><a routerLink="/blog-details">The In and Out of a Successfull ML Startup</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod...</p>
                        <a routerLink="/blog-details" class="learn-more-btn">Read More <i class="flaticon-add"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="entry-thumbnail">
                        <a routerLink="/blog-details"><img src="assets/img/blog/blog4.jpg" alt="image"></a>
                    </div>
                    <div class="entry-post-content">
                        <div class="entry-meta">
                            <ul>
                                <li><a routerLink="/">Admin</a></li>
                                <li>August 15, 2023</li>
                            </ul>
                        </div>
                        <h3><a routerLink="/blog-details">Machine Learning Startup Best Practices for Success</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod...</p>
                        <a routerLink="/blog-details" class="learn-more-btn">Read More <i class="flaticon-add"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="entry-thumbnail">
                        <a routerLink="/blog-details"><img src="assets/img/blog/blog5.jpg" alt="image"></a>
                    </div>
                    <div class="entry-post-content">
                        <div class="entry-meta">
                            <ul>
                                <li><a routerLink="/">Admin</a></li>
                                <li>August 18, 2023</li>
                            </ul>
                        </div>
                        <h3><a routerLink="/blog-details">Chatbot Startup Formation and Market Achievement for Longer Run</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod...</p>
                        <a routerLink="/blog-details" class="learn-more-btn">Read More <i class="flaticon-add"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="entry-thumbnail">
                        <a routerLink="/blog-details"><img src="assets/img/blog/blog6.jpg" alt="image"></a>
                    </div>
                    <div class="entry-post-content">
                        <div class="entry-meta">
                            <ul>
                                <li><a routerLink="/">Admin</a></li>
                                <li>August 15, 2023</li>
                            </ul>
                        </div>
                        <h3><a routerLink="/blog-details">How Instgram Built Their ML & AI Based Team?</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod...</p>
                        <a routerLink="/blog-details" class="learn-more-btn">Read More <i class="flaticon-add"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="pagination-area">
                    <a routerLink="/" class="prev page-numbers"><i class="fas fa-angle-double-left"></i></a>
                    <a routerLink="/" class="page-numbers">1</a>
                    <span class="page-numbers current" aria-current="page">2</span>
                    <a routerLink="/" class="page-numbers">3</a>
                    <a routerLink="/" class="page-numbers">4</a>
                    <a routerLink="/" class="next page-numbers"><i class="fas fa-angle-double-right"></i></a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Blog Area -->
