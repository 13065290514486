<!-- Start Page Title Area -->
<div class="page-title-area item-bg1">
    <div class="container">
        <div class="page-title-content">
            <h2>Dashboard</h2>
            <!-- <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Projects</li>
            </ul> -->
        </div>
    </div>

    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</div>
<!-- End Page Title Area -->